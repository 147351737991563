import dayjs from "dayjs"
import router from '@/router'


const getDefaultState = () => { // state
    return {

        // PendingOrderSelectView中勾選的項目
        item_selected: sessionStorage.getItem("material_pending_item_selected")
                        ? JSON.parse(sessionStorage.getItem("material_pending_item_selected"))
                        : [],

        // 這個由待確認新增的運送訂單ID (用於顯示使用)
        pending_created_shipping_id: null,
     
        
    }
}

export default {
    namespaced: true,
    state: getDefaultState(),

    mutations: { 
        setItemSelected(state, payload){ // 定義日期
            state.item_selected = payload
        },

        setPendingCreatedShippingID(state, payload){
            state.pending_created_shipping_id = payload
        },
    },
    
    actions: { 
        setItemSelected({state, commit}, payload){
            commit("setItemSelected", payload)

            // 將日期儲存至Session
            sessionStorage.setItem('material_pending_item_selected', JSON.stringify(payload))
        },
        removeItemSelected({state, commit}){
            commit("setItemSelected", [])
            sessionStorage.removeItem('material_pending_item_selected')
        },
    },

}